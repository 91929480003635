import { Elm } from "./src/Main.elm";
import "./scss/style.scss";

if (module.hot) {
  module.hot.dispose(() => {
    window.location.reload();
  });
}

const flags = {};

const app = Elm.Main.init({ flags });

function scrollTo(options) {
  var element = document.getElementById(options.elementId);
  var viewport = document.getElementById(options.viewportId);
  if (element) {
    var rect = element.getBoundingClientRect();
    //var y = window.pageYOffset;
    var top = viewport.scrollTop + rect.top - options.offset;
    if ("attachShadow" in element) {
      viewport.scrollTo({ behavior: options.behavior, top });
    } else {
      window.scrollTop = top;
    }
  }
}

if (app.ports && app.ports.smoothScrollTop) {
  app.ports.smoothScrollTop.subscribe(scrollTo);
}

if (process.env.NODE_ENV === "production") {
  // Fathom - stats without tracking or storing personal data of your users
  document.cookie =
    "_ga=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.cedricsoulas.com;";
  (function(f, a, t, h, o, m) {
    a[h] =
      a[h] ||
      function() {
        (a[h].q = a[h].q || []).push(arguments);
      };
    (o = f.createElement("script")), (m = f.getElementsByTagName("script")[0]);
    o.async = 1;
    o.src = t;
    o.id = "fathom-script";
    m.parentNode.insertBefore(o, m);
  })(document, window, "//cdn.usefathom.com/tracker.js", "fathom");
  fathom("set", "siteId", "LPDDCIJR");
  fathom("trackPageview");
  fathom("set", "spa", "hash");
  fathom("set", "spa", "pushstate");
}
